<template>
  <div class="page">
    <page-header
      :nav-items="navItems"
      :nav-user-items="[]"
      :logged-in="loggedIn"
      :username="username"
      :class="{ 'shadow-sm': !$slots.filters }"
      data-test="header"
    >
      <img slot="logo" src="../../assets/images/my-port-logo.svg" alt="My Port logo" class="logo" />
      <locale-switch v-if="showLocaleSwitch" slot="language-selector" />
    </page-header>
    <page-filters v-if="$slots.filters" class="shadow-sm page__filters-wrapper"> <slot name="filters" /> </page-filters>

    <div class="px-3 page__content" :class="`page__container--${containerType}`"><slot /></div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import PageHeader from 'poronline-shared-vue-components/components/page/heading';
import pageFilters from 'poronline-shared-vue-components/components/page/filters';
import localeSwitch from '../locale-switch';

export default {
  name: 'Page',
  props: {
    containerType: {
      type: String,
      validator: (value) => ['centered', 'centered-sm', 'fluid'].includes(value),
      default: 'fluid',
    },
  },
  components: {
    PageHeader,
    localeSwitch,
    pageFilters,
  },
  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.isAuthenticated,
    }),
    ...mapGetters({
      userProfile: 'USER_PROFILE',
      showLocaleSwitch: 'FEATURE_LOCALE_SWITCH',
    }),
    navItems() {
      return this.loggedIn
        ? [
            { label: this.$t('view.home.title'), to: { name: 'dashboard' } },
            { label: this.$t('view.visits.title'), to: { name: 'visits' } },
            { label: this.$t('view.requests.title'), to: { name: 'requests' } },
          ]
        : [{ label: this.$t('view.home.login.titleShort'), to: { name: 'login' } }];
    },
    username() {
      if (!this.userProfile) {
        return null;
      }
      const { fullname, organisation } = this.userProfile;
      if (fullname && organisation) {
        return `${fullname} (${organisation})`;
      }

      return fullname || this.$t('view.login.unknown');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~poronline-shared-vue-components/styles/variables';
@import '~poronline-shared-vue-components/styles/functions';

.page {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .logo {
    height: 30px;
    width: 30px;
    margin: spacer(2) spacer(2) spacer(2) spacer(3);
  }

  &__content {
    max-height: 100%;
    display: flex;
    flex: 0 1 100%;
    width: 100%;
    // https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/
    min-height: 0;
  }

  &__container--fluid {
    @include make-container();
  }

  &__container--centered {
    @include make-container();

    @include make-container-max-widths(
      $max-widths: (
        sm: $centered-page-container-width,
        md: $centered-page-container-width,
        lg: $centered-page-container-width,
        xl: $centered-page-container-width,
      )
    );
  }

  &__container--centered-sm {
    @include make-container();

    @include make-container-max-widths(
      $max-widths: (
        sm: $narrow-page-container-width,
        md: $narrow-page-container-width,
        lg: $narrow-page-container-width,
        xl: $narrow-page-container-width,
      )
    );
  }
}
</style>
