<template>
  <div class="locale-changer pr-4">
    <b-form-select class="pr-5" v-model="$i18n.locale">
      <option class="mr-4" v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
    </b-form-select>
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';
import { localeOptions } from '../locales';

export default {
  name: 'locale-changer',
  components: { BFormSelect },
  data() {
    return { langs: localeOptions };
  },
};
</script>
